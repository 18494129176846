import React from 'react'

function Map() {
    return (
        <div
            className="mb-0"
            style={{
                filter: 'grayscale(60%)',
            }}
        >
            <iframe
                title="Brett's Working Area"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d124772.69021138974!2d-94.54803771589701!3d39.13480379264432!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87c0f0a86bb701a7%3A0x61a5104e16dbd700!2sNorth%20Kansas%20City%2C%20MO!5e0!3m2!1sen!2sus!4v1679534028068!5m2!1sen!2sus"
                width="100%"
                height="450"
                style={{ border: '0' }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
        </div>
    )
}

export default Map
